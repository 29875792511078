import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'

// this component scrolls the browser window to top on every router transition
export const ScrollToTop = (props: React.PropsWithChildren): React.ReactElement => {
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <Fragment>{props.children}</Fragment>
}
