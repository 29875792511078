import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { authStorageKey } from '@constants/configuration'
import { Authentication, LogoutReason } from '@api/endpoints/auth'

type State = Readonly<{
  auth: OptionalType<Authentication>
  logoutReason: OptionalType<LogoutReason>
  isAuthenticated: boolean
}>

type Actions = Readonly<{
  setAuth: Handler<Authentication>
  invalidate: Handler<OptionalType<LogoutReason>>
}>

// TODO: Maybe we can use computed value isAuthenticated here (need more library investigation)
export const useAuthStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        auth: undefined,
        logoutReason: undefined,
        isAuthenticated: false,
        setAuth: (auth) =>
          set(() => ({
            auth,
            logoutReason: undefined,
            isAuthenticated: true
          })),
        invalidate: (logoutReason) =>
          set(() => ({
            auth: undefined,
            logoutReason,
            isAuthenticated: false
          }))
      }),
      {
        name: authStorageKey,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
