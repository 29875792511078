import * as z from 'zod'
import { PasswordSchema, UserIdSchema, UserNameSchema } from '@api/endpoints'

export type LogoutReason = 'SignedOut' | 'InvalidLogin' | 'TimedOut'

export const UserRoleEnumSchema = z.enum(['ADMIN', 'USER'])
export type UserRoleEnum = z.infer<typeof UserRoleEnumSchema>

export const Authentication = z.object({
  userId: UserIdSchema,
  userName: UserNameSchema,
  role: UserRoleEnumSchema
})
export type Authentication = z.infer<typeof Authentication>

export const AuthenticationRequest = z.object({
  userName: UserNameSchema,
  password: PasswordSchema
})
export type AuthenticationRequest = z.infer<typeof AuthenticationRequest>
