import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isHttpError, isInvalidRequestError, isInvalidResponseError, isUnknownError } from '@digital-magic/zod-fetch'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { QueryErrorHandlerHook } from './types'

export const useDefaultQueryErrorHandlerPublic: QueryErrorHandlerHook = () => {
  const { t } = useTranslation()
  const { enqueueErrorMsg } = useSnackbarActions()

  return React.useCallback(
    (e) => {
      if (appDevMode) {
        // eslint-disable-next-line no-console
        console.error('Request handling error', e)
      }
      if (isUnknownError(e)) {
        enqueueErrorMsg('APP Error: Unknown error')
      } else if (isHttpError(e)) {
        switch (e.response.httpStatus) {
          case 400:
            enqueueErrorMsg(t('global.errors.error_check_request'))
            break
          case 401:
            enqueueErrorMsg(t('global.errors.error_unauthorized_request'))
            break
          case 404:
            enqueueErrorMsg(t('global.errors.error_resource_not_found'))
            break
          case 409:
            enqueueErrorMsg(t('global.errors.error_conflict'))
            break
          case 500:
            enqueueErrorMsg(t('global.errors.error_server'))
            break
          default:
            enqueueErrorMsg(t('global.errors.error_unknown'))
            break
        }
      } else if (isInvalidRequestError(e)) {
        enqueueErrorMsg(t('global.errors.error_invalid_request'))
      } else if (isInvalidResponseError(e)) {
        enqueueErrorMsg(t('global.errors.error_invalid_response'))
      } else if (e instanceof Error) {
        enqueueErrorMsg(t('global.errors.error_system'))
      } else {
        enqueueErrorMsg(t('global.errors.error_object'))
      }
    },
    [enqueueErrorMsg, t]
  )
}
