import * as React from 'react'
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '@stores/useAuthStore'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { Authentication, LogoutReason } from '@api/endpoints/auth'
import { defaultPrivateRoute } from '@constants/routes'
import {
  useMutationInvalidateAuthentication,
  useMutationRequestAuthenticate,
  useQueryGetAuthentication
} from '@hooks/ReactQuery/auth'
import { AuthContextResult } from '@hooks/AuthContext/types'

export const AuthContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const [needUserInfo, setNeedUserInfo] = React.useState(false)

  const setAuth = useAuthStore((s) => s.setAuth)
  const invalidate = useAuthStore((s) => s.invalidate)
  const logoutReason = useAuthStore((s) => s.logoutReason)

  const handleAuthError = (reason: OptionalType<LogoutReason>): void => {
    setNeedUserInfo(false)
    invalidate(reason)
  }

  const onAuthenticationSuccess = (authInfo: Authentication): void => {
    setAuth(authInfo)
    setNeedUserInfo(true)
    navigate(defaultPrivateRoute, { replace: true })
  }

  const onInvalidateAuthSuccess = (): void => {
    setNeedUserInfo(false)
    invalidate('SignedOut')
  }

  useQueryGetAuthentication({
    enabled: needUserInfo,
    onSuccess: onAuthenticationSuccess,
    onInvalidAuthentication: () => handleAuthError('TimedOut')
  })

  const authenticate = useMutationRequestAuthenticate({
    onInvalidAuthentication: () => handleAuthError('InvalidLogin'),
    onSuccess: () => setNeedUserInfo(true)
  })

  const invalidateAuth = useMutationInvalidateAuthentication({
    onInvalidAuthentication: () => handleAuthError(undefined),
    onSuccess: onInvalidateAuthSuccess
  })

  const login: AuthContextResult['login'] = React.useCallback((request) => authenticate.mutate(request), [authenticate])
  const logout: AuthContextResult['logout'] = React.useCallback(() => invalidateAuth.mutate(), [invalidateAuth])

  return <AuthContext.Provider value={{ login, logout, logoutReason }}>{children}</AuthContext.Provider>
}
