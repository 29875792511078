import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { Handler } from '@digital-magic/ts-common-utils'
import { appStorageKey, defaultTheme } from '@constants/configuration'
import { PaletteMode } from '@mui/material'

export type State = Readonly<{
  themeType: PaletteMode
}>

export type Actions = Readonly<{
  setThemeType: Handler<PaletteMode>
}>

export const useAppStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        themeType: defaultTheme,
        setThemeType: (theme) => set(() => ({ themeType: theme }))
      }),
      {
        name: appStorageKey,
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
)
