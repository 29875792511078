import * as z from 'zod'
import { getYear } from 'date-fns'
import { defaultMaxFileSize } from '@constants/configuration'
import { ZodCustomErrorCode } from '@api/errors'

const currentYear = getYear(new Date())

// Common data types

export const FileTypeSchema = z
  .custom<File>((v) => v instanceof File)
  .superRefine((v: File, ctx) => {
    if (v.size > defaultMaxFileSize) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          customCode: ZodCustomErrorCode.FileTooLarge,
          maxFileSize: defaultMaxFileSize
        }
      })
    }
  })
export type FileType = z.infer<typeof FileTypeSchema>

export const UrlStringSchema = z.string().min(4).max(256)
export type UrlString = z.infer<typeof UrlStringSchema>

// TODO: Add pattern list when it'll be added on server-side
export const PhoneNumberSchema = z.string().min(4).max(20)
export type PhoneNumber = z.infer<typeof PhoneNumberSchema>

export const UserNameSchema = z.string().min(4).max(50)
export type UserName = z.infer<typeof UserNameSchema>

export const PriceSchema = z.number().int().positive()
export type Price = z.infer<typeof PriceSchema>

export const PasswordSchema = z.string().min(6).max(42)
export type Password = z.infer<typeof PasswordSchema>

export const FreeFormTextSchema = z.string().min(1).max(3000)
export type FreeFormText = z.infer<typeof FreeFormTextSchema>

export const OrderNumberSchema = z.number().int().nonnegative()
export type OrderNumber = z.infer<typeof OrderNumberSchema>

export const PersonalIdCodeSchema = z.string().min(11).max(11)
export type PersonalIdCode = z.infer<typeof PersonalIdCodeSchema>

// Shared ID types

const StringIdSchema = z.string().min(1)
const NumericIdSchema = z.number().int().positive()

export const CaptchaTokenSchema = StringIdSchema
export type CaptchaToken = z.infer<typeof CaptchaTokenSchema>

export const FormIdSchema = StringIdSchema
export type FormId = z.infer<typeof FormIdSchema>

export const FileIdSchema = StringIdSchema
export type FileId = z.infer<typeof FileIdSchema>

export const UserIdSchema = NumericIdSchema
export type UserId = z.infer<typeof UserIdSchema>

export const VehicleIdSchema = NumericIdSchema
export type VehicleId = z.infer<typeof VehicleIdSchema>

// Vehicle basic types (shared across multiple APIs)

export const VehicleMakeSchema = z.string().min(1)
export type VehicleMake = z.infer<typeof VehicleMakeSchema>

export const VehicleModelSchema = z.string().min(1)
export type VehicleModel = z.infer<typeof VehicleModelSchema>

export const VehicleModelExtraSchema = z.string().min(1)
export type VehicleModelExtra = z.infer<typeof VehicleModelExtraSchema>

export const VehicleRegistrationNumberSchema = z.string().min(0).max(10)
export type VehicleRegistrationNumber = z.infer<typeof VehicleRegistrationNumberSchema>

export const VinCodeSchema = z.string().min(2).max(17)
export type VinCode = z.infer<typeof VinCodeSchema>

// TODO: Int or Decimal?
export const FuelConsumptionSchema = z.number().nonnegative()
export type FuelConsumption = z.infer<typeof FuelConsumptionSchema>

export const MileageSchema = z.number().nonnegative()
export type Mileage = z.infer<typeof MileageSchema>

export const EnginePowerSchema = z.number().int().nonnegative()
export type EnginePower = z.infer<typeof EnginePowerSchema>

export const EngineVolumeSchema = z.number().int().nonnegative()
export type EngineVolume = z.infer<typeof EngineVolumeSchema>

export const EngineDisplacementSchema = z.string().min(1)
export type EngineDisplacement = z.infer<typeof EngineDisplacementSchema>

export const VehicleYearSchema = z.number().int().min(1970).max(currentYear)
export type VehicleYear = z.infer<typeof VehicleYearSchema>

export const VehicleLocationSchema = z.string().min(2).max(50)
export type VehicleLocation = z.infer<typeof VehicleLocationSchema>

// Common request / response types

export const FileUploadResponseSchema = z.object({
  fileId: FileIdSchema
})
export type FileUploadResponse = z.infer<typeof FileUploadResponseSchema>

export const ImageUploadResponseSchema = z.object({
  imageUrl: UrlStringSchema,
  thumbnailUrl: UrlStringSchema
})
export type ImageUploadResponse = z.infer<typeof ImageUploadResponseSchema>

export const OrderedImageSchema = ImageUploadResponseSchema.extend({
  orderNumber: OrderNumberSchema
})
export type OrderedImage = z.infer<typeof OrderedImageSchema>
