/* eslint-disable functional/immutable-data */
import { DataType } from 'csstype'
import { createTheme, Theme } from '@mui/material/styles'
import { commonColors } from './colors'
import { ButtonOwnProps } from '@mui/material'

export const getMainColor = (theme: Theme, color: ButtonOwnProps['color']): DataType.Color | undefined => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    case 'success':
      return theme.palette.success.main
    case 'error':
      return theme.palette.error.main
    case 'info':
      return theme.palette.info.main
    case 'warning':
      return theme.palette.warning.main
    case 'darkBlue':
      return theme.palette.darkBlue.main
    case 'blue':
      return theme.palette.blue.main
    case 'white':
      return theme.palette.white.main
    case 'inherit':
      return 'inherit'
    case undefined:
      return undefined
  }
}

export const buildCustomTheme = (primaryColor: DataType.Color, secondaryColor: DataType.Color): Theme =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      darkBlue: {
        main: commonColors.blue
      },
      blue: {
        main: commonColors.blue007
      },
      white: {
        main: commonColors.white
      }
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableRipple: true
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            padding: ownerState.variant === 'text' ? 0 : undefined,
            color:
              ownerState.color === 'white'
                ? theme.palette.darkBlue.main
                : ownerState.variant === 'contained'
                ? commonColors.white
                : getMainColor(theme, ownerState.color),
            backgroundColor: ownerState.variant === 'contained' ? getMainColor(theme, ownerState.color) : 'inherit',
            borderWidth: ownerState.variant === 'contained' && ownerState.color === 'white' ? 1 : undefined,
            borderStyle: ownerState.variant === 'contained' && ownerState.color === 'white' ? 'solid' : undefined,
            borderColor:
              ownerState.variant === 'outlined'
                ? ownerState.color === 'white'
                  ? theme.palette.darkBlue.main
                  : getMainColor(theme, ownerState.color)
                : ownerState.color === 'white'
                ? commonColors.grayBE
                : 'inherit',

            ':hover': {
              backgroundColor: ownerState.variant === 'contained' ? getMainColor(theme, ownerState.color) : 'inherit',
              borderColor:
                ownerState.color === 'white' && ownerState.variant === 'outlined'
                  ? theme.palette.darkBlue.main
                  : undefined,
              opacity: 0.9
            }
          })
        }
      }
    },
    typography: {
      fontSize: 16,
      fontFamily: 'Montserrat, Roboto, sans-serif'
    }
  })
