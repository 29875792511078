import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from '@api/queryClient'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import '@assets/css/theme/themeLightBundle.css'
import { useAppStore } from '@stores/useAppStore'
import { AuthContextProvider } from '@hooks/AuthContext'
import { themeByType } from '@hooks/useTheme'
import { ScrollToTop } from '@controls/ScrollToTop'
import { LoadingIndicator } from '@controls/LoadingIndicator'
import { AppRoutes } from './AppRoutes'
import { i18n } from './i18n'

const AppWithContexts: React.FC = () => {
  // obtain the theme saved in the browser local storage
  const themeType = useAppStore((s) => s.themeType)
  const currentTheme = themeByType(themeType)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={(snackbarId) => (
            <a
              href="#"
              onClick={() => closeSnackbar(snackbarId)}
              style={{ textDecoration: 'none', color: 'white', position: 'absolute', top: '0.1rem', right: '0.3rem' }}
            >
              {'✖'}
            </a>
          )}
        >
          <AuthContextProvider>
            <React.Suspense fallback={<LoadingIndicator />}>
              <AppRoutes />
            </React.Suspense>
          </AuthContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export const App: React.FC = () => (
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter basename={`${baseUrl}${i18n.language}`}>
        <ScrollToTop>
          <StyledEngineProvider injectFirst>
            <AppWithContexts />
          </StyledEngineProvider>
        </ScrollToTop>
      </BrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>
)
