import * as React from 'react'
import { generatePath, Params, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FN, Predicate } from '@digital-magic/ts-common-utils'
import { absoluteBaseUrl } from '@constants/configuration'
import { PublicRouteKey, publicRoutes } from '@constants/routes'
import { UserLanguageEnum, userLanguage } from '@model/language'

export type UseLanguageReturn = {
  language: UserLanguageEnum
  routes: ReturnType<typeof publicRoutes>
  currentRoutePath: string
  currentRouteAbsolutePath: string
  currentRoutePathForLanguage: FN<UserLanguageEnum, string>
}

const searchRecord =
  <K extends keyof unknown, V>(predicate: Predicate<[K, V]>) =>
  (routes: Record<K, V>): [K, V] | undefined =>
    (Object.entries(routes) as Array<[K, V]>).find(predicate)

const matchPathPredicate =
  (path: string, params: Params): Predicate<[PublicRouteKey, string]> =>
  ([, route]) => {
    // eslint-disable-next-line functional/no-try-statements
    try {
      return route.indexOf(':') !== -1 && Object.keys(params).length > 0
        ? generatePath(route, params) === path
        : path === route
    } catch (e) {
      return false
    }
  }

const buildRelativeRoutePath = (language: UserLanguageEnum, routePath: string, search?: string): string => {
  const path = `${language}${routePath}`
  return search ? `${path}${search}` : path
}

const buildAbsoluteRoutePath = (language: UserLanguageEnum, routePath: string, search?: string): string =>
  `${absoluteBaseUrl}${buildRelativeRoutePath(language, routePath, search)}`

export const useLanguage = (): UseLanguageReturn => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const params = useParams()

  const language = React.useMemo(() => userLanguage(i18n.language), [i18n])
  const routes = React.useMemo(() => publicRoutes(language), [language])

  const findCurrentRouteKey = React.useCallback(
    (): PublicRouteKey =>
      searchRecord(matchPathPredicate(location.pathname, params))(publicRoutes(language))?.[0] ?? 'Index',
    [language, location.pathname, params]
  )

  const currentRoutePathForLanguage = React.useCallback(
    (lang: UserLanguageEnum): string => {
      const routePathValue = publicRoutes(lang)[findCurrentRouteKey()]
      const routePath = Object.keys(params).length > 0 ? generatePath(routePathValue, params) : routePathValue
      return buildAbsoluteRoutePath(lang, routePath, location.search)
    },
    [findCurrentRouteKey, location.search, params]
  )

  const currentRoutePath = React.useMemo(
    () => buildRelativeRoutePath(language, location.pathname, undefined),
    [language, location.pathname]
  )

  const currentRouteAbsolutePath = React.useMemo(
    () => buildAbsoluteRoutePath(language, location.pathname, undefined),
    [language, location.pathname]
  )

  return React.useMemo(
    () => ({
      language,
      currentRoutePathForLanguage,
      routes,
      currentRoutePath,
      currentRouteAbsolutePath
    }),
    [currentRoutePath, currentRouteAbsolutePath, language, routes, currentRoutePathForLanguage]
  )
}
