import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './LoadingIndicator.module.css'
import { FlexContainer } from '@layout/FlexContainer'

export const LoadingIndicator: React.FC = () => {
  const { t } = useTranslation()
  return (
    <FlexContainer vertical alignItemsCenter justifyContentCenter className={styles.loadingContainer}>
      <h2 className={styles.loading}>{t('controls.loading-indicator.loading')}</h2>
      <div className={styles.loader}></div>
    </FlexContainer>
  )
}
