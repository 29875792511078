import { useTranslation } from 'react-i18next'
import { arrayIncludes } from '@digital-magic/ts-common-utils'
import { languageConfiguration } from '@constants/configuration'
import { UserLanguageEnum, UserLanguageEnumSchema } from './model'

export type AvailableLanguage = (typeof languageConfiguration.availableLanguages)[number]

export const userLanguage = (lang: string): UserLanguageEnum => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (arrayIncludes(languageConfiguration.availableLanguages, lang)) {
    switch (lang) {
      case 'en':
        return UserLanguageEnumSchema.enum.en
      case 'ru':
        return UserLanguageEnumSchema.enum.ru
      default:
        return UserLanguageEnumSchema.enum.et
    }
  } else {
    // This code ensures that english is present in both availableLanguage & defaultLanguage configurations
    const en: AvailableLanguage = 'en'
    if (!languageConfiguration.defaultLanguage.includes(en)) {
      // eslint-disable-next-line no-console
      console.error(
        'Fallback language is not defined in languages list for i18n configuration: ',
        languageConfiguration.defaultLanguage
      )
    }
    return UserLanguageEnumSchema.enum.en
  }
}

export function formatEuroInEstonian(sumInEuro: number, maximumFractionDigits = 0): string {
  return new Intl.NumberFormat('et-EE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: maximumFractionDigits
  }).format(sumInEuro)
}

export function formatNumberInEstonian(number: number, maximumFractionDigits = 0): string {
  return new Intl.NumberFormat('et-EE', {
    style: 'decimal',
    maximumFractionDigits: maximumFractionDigits
  }).format(number)
}

export function useFormatDateAsMonthAndYear(date: Date): string {
  const { i18n } = useTranslation()

  const options = { month: 'long' }
  const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, options as { month: 'long' })

  // for russian language, the formatter adds an unwanted "г." after the year, so we format the year ourselves
  return `${dateTimeFormat.format(date)} ${date.getFullYear().toString()}`
}
