import * as React from 'react'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { hasValue } from '@digital-magic/ts-common-utils'
import { isHttpError } from '@digital-magic/zod-fetch'
import { deleteAuthentication } from '@api/endpoints/auth/requests'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetAuthentication } from './queryKeys'

type Props = Readonly<{
  onSuccess?: () => void
  onInvalidAuthentication?: () => void
}>

export const useMutationInvalidateAuthentication = ({
  onSuccess,
  onInvalidAuthentication
}: Props): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient()
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()
  const mutation = useMutation({
    mutationFn: deleteAuthentication
  })

  const errorHandler: QueryErrorHandler = (e) => {
    if (
      hasValue(onInvalidAuthentication) &&
      isHttpError(e) &&
      (e.response.httpStatus === 401 || e.response.httpStatus === 403)
    ) {
      onInvalidAuthentication()
    } else {
      defaultErrorHandler(e)
    }
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void queryClient.invalidateQueries({ queryKey: queryKeyGetAuthentication })
      onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
