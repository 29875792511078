/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { httpCall, httpReceiveOnlyJson, httpSendOnlyJson } from '@digital-magic/zod-fetch'
import { apiBaseUrlV1 } from '@constants/configuration'
import { Authentication, AuthenticationRequest } from './types'

const authUrl = `${apiBaseUrlV1}/auth`

export const getAuthentication = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: authUrl,
    responseSchema: Authentication
  })

export const postAuthenticateRequest = (data: AuthenticationRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: authUrl,
    requestSchema: AuthenticationRequest,
    data
  })

export const deleteAuthentication = () =>
  httpCall({
    method: 'DELETE',
    url: authUrl
  })
