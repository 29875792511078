import { UserLanguageEnum } from '@model/language'

type TranslatedRouteValue = Record<UserLanguageEnum, string>

type RouteValue = string | TranslatedRouteValue

const adminRoute = '/admin'
const usersRoute = adminRoute + '/users'
const carsRoute = adminRoute + '/cars'

const publicRouteMapping = {
  Index: '/',
  Catalog: {
    et: '/kasutatud-autod',
    en: '/cars',
    ru: '/avtomobili'
  },
  VehiclePage: {
    et: '/autod/:vehicleId',
    en: '/car/:vehicleId',
    ru: '/avto/:vehicleId'
  },
  Comparison: {
    et: '/vordlus',
    en: '/compare',
    ru: '/sravnenie'
  },
  Installment: {
    et: '/autolaen',
    en: '/carloan',
    ru: '/avtokredit'
  },
  Exchange: {
    et: '/autovahetus',
    en: '/cars-exchange',
    ru: '/avtoobmen'
  },
  Buyback: {
    et: '/valjaost',
    en: '/buying-cars',
    ru: '/pokupka-avtomobilei'
  },
  CarBuyback: {
    et: '/autode-kokkuost',
    en: '/buying-cars',
    ru: '/pokupka-avtomobilei'
  },
  Commission: {
    et: '/komisjonimuuk',
    en: '/commission',
    ru: '/komissionnaja-prodazha'
  },
  Guarantee: {
    et: '/garantii',
    en: '/warranty',
    ru: '/garantija'
  },
  Delivery: {
    et: '/kohaletoimetamine',
    en: '/dostavka',
    ru: '/cars-delivery'
  },
  About: {
    et: '/meist',
    en: '/firma',
    ru: '/company'
  },
  PrivacyPolicy: {
    et: '/privaatsuspoliitika',
    en: '/privacy-policy',
    ru: '/privatnoe'
  }
} satisfies Record<string, RouteValue>

export type PublicRouteKey = keyof typeof publicRouteMapping

export const externalRoutes = {
  CarprofService: 'https://carprofservice.ee'
} as const

const mapRecord = <K extends string, T, U>(record: Record<K, T>, fn: (value: T) => U): Record<K, U> =>
  Object.entries(record).reduce((acc, [key, value]) => ({ ...acc, [key as K]: fn(value as T) }), {} as Record<K, U>)

export const publicRoutes = (lang: UserLanguageEnum): Readonly<Record<PublicRouteKey, string>> =>
  mapRecord<PublicRouteKey, RouteValue, string>(publicRouteMapping, (value) =>
    typeof value === 'string' ? value : value[lang]
  )

export const authRoutes = {
  Login: '/login'
} as const

export const privateRoutes = {
  Admin: adminRoute,
  Users: `${usersRoute}/list`,
  UserNew: `${usersRoute}/new`,
  UserEdit: `${usersRoute}/:userId`,
  Cars: `${carsRoute}/list`,
  CarsDetail: `${carsRoute}/:carId`,
  CarOffer: `${carsRoute}/:carId/offer`
} as const

export const defaultPrivateRoute = privateRoutes.Cars
export const defaultPublicRoute = publicRouteMapping.Index
