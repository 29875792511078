import * as React from 'react'
import clsx from 'clsx'
import { CommonComponentProps } from '@controls/types'
import styles from './FlexContainer.module.css'

export type FlexContainerProps = Readonly<{
  fixedDirection?: boolean
  wrap?: boolean
  reversedDirection?: boolean
  centeredInBlock?: boolean
  justifyContentCenter?: boolean
  justifyContentSpaceBetween?: boolean
  justifyContentSpaceAround?: boolean
  alignItemsCenter?: boolean
  vertical?: boolean
  responsiveGap?: boolean
  noGap?: boolean
  fullHeight?: boolean
  fitHeight?: boolean
  fitWidth?: boolean
  halfWidth?: boolean
  bordered?: boolean
  noBorderSm?: boolean
}> &
  CommonComponentProps &
  React.HTMLAttributes<HTMLDivElement>

export const FlexContainer: React.FC<FlexContainerProps> = ({
  fixedDirection,
  wrap,
  reversedDirection,
  centeredInBlock,
  justifyContentCenter,
  justifyContentSpaceBetween,
  justifyContentSpaceAround,
  alignItemsCenter,
  responsiveGap,
  noGap,
  vertical,
  fullHeight,
  fitHeight,
  fitWidth,
  halfWidth,
  bordered,
  noBorderSm,
  className,
  children
}) => (
  <div
    className={clsx(styles.flexContainer, className, {
      [styles.fixedDirection]: fixedDirection,
      [styles.wrap]: wrap,
      [styles.reversedDirection]: reversedDirection, // Do we need this style? We can arrange the elements the way we want in the HTML
      [styles.centeredInBlock]: centeredInBlock,
      [styles.justifyContentCenter]: justifyContentCenter,
      [styles.justifyContentSpaceBetween]: justifyContentSpaceBetween,
      [styles.justifyContentSpaceAround]: justifyContentSpaceAround,
      [styles.alignItemsCenter]: alignItemsCenter,
      [styles.verticalDirection]: vertical,
      [styles.fixedGap]: !noGap && !responsiveGap,
      [styles.responsiveGap]: !noGap && responsiveGap,
      [styles.fullHeight]: fullHeight,
      [styles.fitHeight]: fitHeight,
      [styles.fitWidth]: fitWidth,
      [styles.halfWidth]: halfWidth,
      [styles.bordered]: bordered,
      [styles.noBorderSm]: noBorderSm
    })}
  >
    {children}
  </div>
)
