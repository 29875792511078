import * as React from 'react'
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack'

export type UseSnackbarActionsResult = Readonly<{
  enqueueErrorMsg: (message: SnackbarMessage) => SnackbarKey
  enqueueSuccessMsg: (message: SnackbarMessage) => SnackbarKey
  enqueueWarningMsg: (message: SnackbarMessage) => SnackbarKey
}>

export const useSnackbarActions = (): UseSnackbarActionsResult => {
  const { enqueueSnackbar } = useSnackbar()

  return React.useMemo(
    () => ({
      enqueueErrorMsg: (message: SnackbarMessage) => enqueueSnackbar({ message, variant: 'error', persist: true }),
      enqueueSuccessMsg: (message: SnackbarMessage) => enqueueSnackbar({ message, variant: 'success' }),
      enqueueWarningMsg: (message: SnackbarMessage) => enqueueSnackbar({ message, variant: 'warning' })
    }),
    [enqueueSnackbar]
  )
}
