import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { hasValue } from '@digital-magic/ts-common-utils'
import { isHttpError } from '@digital-magic/zod-fetch'
import { Authentication, getAuthentication } from '@api/endpoints/auth'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetAuthentication } from './queryKeys'

export type Props = Readonly<{
  onSuccess?: (authInfo: Authentication) => void
  onInvalidAuthentication?: () => void
  enabled?: boolean
}>

export const useQueryGetAuthentication = ({
  enabled,
  onSuccess,
  onInvalidAuthentication
}: Props): UseQueryResult<Authentication> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetAuthentication,
    queryFn: getAuthentication,
    enabled: enabled
  })

  React.useEffect(() => {
    if (query.error) {
      // TODO: Not every 401/403 means TimedOut - need more precise handling (server must send this reason)
      if (
        hasValue(onInvalidAuthentication) &&
        isHttpError(query.error) &&
        (query.error.response.httpStatus === 401 || query.error.response.httpStatus === 403)
      ) {
        onInvalidAuthentication()
      } else {
        errorHandler(query.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  React.useEffect(() => {
    if (query.isSuccess) {
      onSuccess?.(query.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess])

  return query
}
