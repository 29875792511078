import { getWindowProperty } from '@digital-magic/react-common/lib/utils/window'
import { UserLanguageEnumSchema } from '@model/language'
import { PaletteMode } from '@mui/material'
import type { LanguageConfiguration } from '@digital-magic/react-common/lib/i18n'

// Versions and paths
export const sentryDsn = getWindowProperty('REACT_APP_SENTRY_DSN') ?? '-'
export const gtmKey = getWindowProperty('REACT_APP_GTM_KEY') ?? '-'
export const gtagId = getWindowProperty('REACT_APP_GTAG_ID') ?? '-'
export const fbPixelId = getWindowProperty('REACT_APP_FB_PIXEL_ID') ?? '-'
export const adsID = getWindowProperty('REACT_APP_AW_KEY') ?? '-'

export const absoluteBaseUrl = `${window.location.origin}${baseUrl}`
export const apiBaseUrl = getWindowProperty('REACT_APP_API_BASE_URL') ?? ''
export const apiBaseUrlV1 = `${apiBaseUrl}/v1`
export const apiBaseUrlV2 = `${apiBaseUrl}/v2`

export const carVerticalParamA = getWindowProperty('REACT_APP_CAR_VERTICAL_PARAM_A') ?? ''
export const carVerticalParamB = getWindowProperty('REACT_APP_CAR_VERTICAL_PARAM_B') ?? ''

export const captchaKey = getWindowProperty('REACT_APP_CAPTCHA_KEY') ?? ''

// eslint-disable-next-line no-console
console.log(`Initialized with params: APP_VERSION=${appVersion}, BASE_URL=${baseUrl}, API_BASE_URL=${apiBaseUrl}`)

// Formatting
export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm'
export const dateTimeShortFormat = `${dateFormat} ${timeFormat}`
export const dateTimeFullFormat = `yyyy-MM-dd ${timeFormat}`
export const displayDateFormat = 'dd-MM-yyyy'
export const priceCurrency = 'EUR'

export const phoneNumber = '+37259080808'
export const mailAddress = 'autokeskus@carprof.ee'

export const defaultMaxFileSize = 10 * 1024 * 1024 // 10 MB

// Other
const storagePrefix = 'carprof-'
export const appStorageKey = `${storagePrefix}_app-storage`
export const authStorageKey = `${storagePrefix}_auth-storage`
export const catalogSortByStorageKey = `${storagePrefix}_catalogSortBy-storage`

export const defaultTheme: PaletteMode = 'light'

export const languageConfiguration: LanguageConfiguration = {
  translationNamespace: 'translations',
  loadPath: `/locales/{{lng}}/{{ns}}.json?v=${appVersion}`, // add app version so file is not cached
  version: appVersion,
  availableLanguages: [UserLanguageEnumSchema.enum.en, UserLanguageEnumSchema.enum.et, UserLanguageEnumSchema.enum.ru],
  defaultLanguage: [UserLanguageEnumSchema.enum.et, UserLanguageEnumSchema.enum.en, UserLanguageEnumSchema.enum.ru],
  storageKey: `${storagePrefix}_i18nextLng`,
  cacheStorageKeyPrefix: `${storagePrefix}_i18next_res_`,
  cacheExpirationMs: appDevMode ? 60 * 1000 : 7 * 24 * 60 * 60 * 1000
}
