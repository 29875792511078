export const commonColors = {
  white: '#fff',
  black: '#000',
  orange: '#EC7230',
  gray10: '#101010',
  grayB7: '#B7B7B7',
  grayBE: '#BEC0C0',
  blue: '#004371',
  blue007: '#0073F0'
} as const
export type CommonColor = typeof commonColors
