import * as React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { RouteObject } from 'react-router/dist/lib/context'
import { authRoutes, defaultPrivateRoute, defaultPublicRoute, privateRoutes } from '@constants/routes'
import { useAuthStore } from '@stores/useAuthStore'
import { useLanguage } from '@hooks/useLanguage'

const LoginPage = React.lazy(() => import('@pages/Login'))
const MainPage = React.lazy(() => import('@pages/Main'))
const AboutPage = React.lazy(() => import('@pages/About'))
const UsersPage = React.lazy(() => import('@pages/Admin/Users'))
const UserPage = React.lazy(() => import('@pages/Admin/Users/User'))
const VehiclePage = React.lazy(() => import('@pages/VehiclePage'))
const CatalogPage = React.lazy(() => import('@pages/Catalog'))
const ExchangePage = React.lazy(() => import('@pages/Exchange'))
const InstallmentPage = React.lazy(() => import('@pages/Installment'))
const GuaranteePage = React.lazy(() => import('@pages/Guarantee'))
const BuyoutPage = React.lazy(() => import('@pages/Buyout'))
const CommissionPage = React.lazy(() => import('src/components/pages/Commission'))
const ComparisonPage = React.lazy(() => import('@pages/Comparison'))
const DeliveryPage = React.lazy(() => import('@pages/Delivery'))
const PrivacyPolicyPage = React.lazy(() => import('@pages/PrivacyPolicy'))
const CarsPage = React.lazy(() => import('@pages/Admin/Cars'))
const CarsDetailPage = React.lazy(() => import('@pages/Admin/Cars/CarsDetail'))
const CarOffer = React.lazy(() => import('@pages/Admin/Cars/CarsDetail/CarOffer'))

export const AppRoutes: React.FC = () => {
  const isAuthenticated = useAuthStore((s) => s.isAuthenticated)
  const { routes } = useLanguage()

  const sharedRoutes: Array<RouteObject> = React.useMemo(
    () => [
      {
        path: routes.Index,
        element: <MainPage />,
        index: true
      },
      {
        path: routes.About,
        element: <AboutPage />
      },
      {
        path: routes.Catalog,
        element: <CatalogPage />
      },
      {
        path: routes.VehiclePage,
        element: <VehiclePage />
      },
      {
        path: routes.Exchange,
        element: <ExchangePage />
      },
      {
        path: routes.Installment,
        element: <InstallmentPage />
      },
      {
        path: routes.Guarantee,
        element: <GuaranteePage />
      },
      {
        path: routes.Buyback,
        element: <BuyoutPage />
      },
      {
        path: routes.CarBuyback,
        element: <BuyoutPage />
      },
      {
        path: routes.Commission,
        element: <CommissionPage />
      },
      {
        path: routes.Comparison,
        element: <ComparisonPage />
      },
      {
        path: routes.Delivery,
        element: <DeliveryPage />
      },
      {
        path: routes.PrivacyPolicy,
        element: <PrivacyPolicyPage />
      }
    ],
    [routes]
  )

  const publicRoutes: Array<RouteObject> = React.useMemo(
    () => [
      ...sharedRoutes,
      {
        path: authRoutes.Login,
        element: <LoginPage />
      },
      {
        path: privateRoutes.Admin,
        element: <Navigate to={authRoutes.Login} />
      },
      {
        path: `${privateRoutes.Admin}/*`,
        element: <Navigate to={authRoutes.Login} />
      },
      {
        path: '*',
        element: <Navigate to={defaultPublicRoute} />
      }
    ],
    [sharedRoutes]
  )

  const securedRoutes: Array<RouteObject> = React.useMemo(
    () => [
      ...sharedRoutes,
      {
        path: privateRoutes.Users,
        element: <UsersPage />
      },
      {
        path: privateRoutes.UserNew,
        element: <UserPage />
      },
      {
        path: privateRoutes.UserEdit,
        element: <UserPage />
      },
      {
        path: privateRoutes.Cars,
        element: <CarsPage />
      },
      {
        path: privateRoutes.CarsDetail,
        element: <CarsDetailPage />
      },
      {
        path: privateRoutes.CarOffer,
        element: <CarOffer />
      },
      {
        path: '*',
        element: <Navigate to={defaultPrivateRoute} />
      }
    ],
    [sharedRoutes]
  )

  const currentRoutes: Array<RouteObject> = React.useMemo(
    () => (isAuthenticated ? securedRoutes : publicRoutes),
    [isAuthenticated, securedRoutes, publicRoutes]
  )

  return useRoutes(currentRoutes)
}
