import * as z from 'zod'

export const ApiErrorCode = z.enum(['CAR_NOT_FOUND', 'INVALID_PHONE_NUMBER'])
export type ApiErrorCode = z.infer<typeof ApiErrorCode>

export const ApiErrorCustomObjectSchema = z.object({
  errorCode: ApiErrorCode
})
export type ApiErrorCustomObject = z.infer<typeof ApiErrorCustomObjectSchema>

export enum ZodCustomErrorCode {
  FileTooLarge = 'file_too_large',
  ConsentRequired = 'consent_required'
}
