import { Handler } from '@digital-magic/ts-common-utils'
import { useAppStore } from '@stores/useAppStore'
import { lightTheme } from '@styles/theme/lightTheme'
import { Theme, useTheme as useThemeMui } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'

type HookResult = {
  currentTheme: Theme
  switchTheme: Handler<PaletteMode>
}

export const themeByType = (themeType: PaletteMode): Theme => {
  switch (themeType) {
    /*
    case 'dark':
      return darkTheme
    */
    default:
      return lightTheme
  }
}

export const useTheme = (): HookResult => {
  const currentTheme = useThemeMui()
  const setThemeType = useAppStore((s) => s.setThemeType)
  return { currentTheme, switchTheme: setThemeType }
}
